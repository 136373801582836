import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import WhatParentsSay from '../components/pages/WhatParentsSay';
import YelpReviews from '../components/pages/YelpReviews';
import SEO from '../components/seo';
import '../styles/app.scss';

const Reviews = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    // const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            {/* <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            /> */}
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <WhatParentsSay />
            <Footer />
        </>
    );
};

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                yelp_reviews {
                    yelp_review_code
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;

export default Reviews;
