import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 5000,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h2 className="yellowtxt">What Parents Say</h2>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “We went there today for the first time and this place Rocks!!!! It is very clean and the staff are all extremely knowledgeable and kind. My almost 2 year old engaged with every single toy/activity there. We will be coming back and spreading the word!”
                            </p>
                            <p className="overview">- Jackie B.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is awesome, all activities are well thought of and the place is very neat.”
                            </p>
                            <p className="overview">- Chris H.</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing and fun kids place! My kids beg me to come all the time! There's many fun activities and toys to play with! The owner and staff is so kind as well!”
                            </p>
                            <p className="overview">
                            - Jennifer V.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This is a great kids gym.  My son loves to come here and play.  There are plenty of different types of swings and other play equipment.  There is a kitchen/diner for pretend time.  Best of all, there is a zip line with a padded crash pit."
                            </p>
                            <p className="overview">
                            - Mark A.
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “This place is the best! The staff is so friendly and is so warm and welcoming to my children. My 4 year old son is very hyper and I never feel judged when coming here. It is a clean and safe space. Great for all ages as I bring my 9 month old daughter also. I highly recommend!! We go about 1-2 times per week."
                            </p>
                            <p className="overview">
                            - Virginia W.
                            </p>
                        </div>

                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
